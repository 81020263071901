<template>
	<Page name="viscoreducteur" 
	title="Le viscoréducteur"
	implementation="1986"
	highestChimney=""
	tonnesHandled=""
	processingTemperature="435 - 450"
	abstract="<p>Cette unité transforme des produits lourds (fuels) provenant de la distillation en produits plus légers (essences, gaz, GPL) sous l’effet de la chaleur (craquage thermique).</p>"
	animation="static/animation/VISCO.json">
	<picture slot="thumbnail">
		<img class="lazy"
		sizes="(max-width: 480px) 100vw, 480px"
		data-srcset="
		img/secteur/VISCO_czmqrr_c_scale_w_350.jpg 350w,
		img/secteur/VISCO_czmqrr_c_scale_w_480.jpg 480w"
		data-src="img/secteur/VISCO_czmqrr_c_scale_w_480.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Page>
</template>

<script>
	export default {
		name: 'viscoreducteur',
		components: {
			Page: () => import('@/components/Page.vue'),
		},
	}
</script>
